import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styled, { ThemeProvider } from 'styled-components'
import is from 'styled-is'

import { getCurrentUser } from '../../redux/reducers'

import { GlobalStyle, theme } from '../../styles'

import Header from './Header'
import MainNav from './Header/MainNav'
import PendingResources from './PendingResources'
import CableDisconnectedAlert from './CableDisconnectedAlert'

export const Container = styled.div`
  height: 100%;
  margin: 0;
  width: 100%;

  display: grid;
  grid-template-rows: 38px 1rem 1fr;
  grid-template-columns: 1fr;
  grid-template-areas:
    'header'
    'pending'
    'content';

  @media (min-width: 1440px) {
    grid-template-columns: 375px 1fr;
    grid-template-areas:
      'nav header'
      'nav pending'
      'nav content';

    ${is('anonymous')`
      grid-template-areas:
        'header header'
        'pending pending'
        'content content';
    `};
  }

  background-color: #fff;
`

export const Content = styled.div`
  padding: 1rem 2rem 2rem;

  grid-area: content;
  overflow-y: auto;
`

const mapStateToProps = (state) => ({
  currentUser: getCurrentUser(state),
})

const Layout = ({ children }) => {
  const [isVisible, setVisible] = useState(false)
  const menuState = { isVisible, setVisible }
  const currentUser = useSelector(getCurrentUser)

  const anonymous = currentUser == null

  return (
    <>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <Container anonymous={anonymous}>
          <PendingResources />
          <Content>{children}</Content>
          <Header {...menuState} anonymous={anonymous} />
          <MainNav {...menuState} />
          <CableDisconnectedAlert />
        </Container>
      </ThemeProvider>
    </>
  )
}

export default Layout
