import {
  USER_CHANNEL_CONNECTED,
  USER_CHANNEL_DISCONNECTED,
  USER_CHANNEL_CONNECTION_ERROR,
} from '../actions'

export default (connected = false, action) => {
  const { type } = action
  switch (type) {
    case USER_CHANNEL_CONNECTED:
      return true
    case USER_CHANNEL_DISCONNECTED:
      return false
    case USER_CHANNEL_CONNECTION_ERROR:
      return false
    default:
      return connected
  }
}

export const isConnected = (state) => state
