import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import parseISO from 'date-fns/parseISO'
import formatDate from 'date-fns/format'
import formatRelative from 'date-fns/formatRelative'

import { registerLocale } from 'react-datepicker'
import dateDE from 'date-fns/locale/de'
import dateEN from 'date-fns/locale/en-US'
import dateIT from 'date-fns/locale/it'

import en from './en.json'
import de from './de.json'
import it from './it.json'

const formatFloat = ['de', 'en', 'it'].reduce((formats, locale) => {
  formats[locale] = new Intl.NumberFormat(locale, {
    minimumFractionDigits: 1,
    maximumFractionDigits: 3,
  })
  return formats
}, {})

export const locales = {
  de: dateDE,
  en: dateEN,
  it: dateIT,
}

i18n.use(initReactI18next).init({
  debug: true,
  lng: document.documentElement.lang,
  fallbackLng: 'de',
  defaultNS: 'common',
  returnObjects: true,
  react: {
    wait: true,
  },
  resources: {
    en,
    de,
    it,
  },
  interpolation: {
    format: (value, format, lng) => {
      if (value instanceof Date) {
        try {
          return formatDate(value, format)
        } catch (RangeError) {
          return ''
        }
      } else if (format === 'relative') {
        if (value == null) {
          return '–'
        } else {
          return formatRelative(parseISO(value), new Date(), {
            locale: locales[lng],
          })
        }
      } else if (format === 'float') {
        return formatFloat[lng].format(value)
      }
      return value
    },
  },
})

Object.keys(locales).forEach((lng) => registerLocale(lng, locales[lng]))

export default i18n
