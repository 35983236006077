import { getStoredToken } from './auth'

export const url =
  process.env.API_ENDPOINT || 'http://api.plantection.test:3000/v1'

export class ApiError extends Error {
  constructor(response, json) {
    super(response.statusText)
    this.name = 'ApiError'
    this.statusText = response.statusText
    this.status = response.status
    this.ok = response.ok
    this.json = json
  }
}

export const formatUrl = (path) => `${url}${path}`

export const request = (path, method = 'GET', body) => {
  return window
    .fetch(formatUrl(path), {
      method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getStoredToken()}`,
      },
      body,
    })
    .then((response) => {
      const meta = {}
      if (response.headers.has('X-TotalPages')) {
        meta.totalPages = +response.headers.get('X-TotalPages')
      }

      if (response.ok) {
        return response
          .json()
          .then((json) => ({ meta, json }))
          .catch(() => undefined)
      } else {
        return response.json().then((json) => {
          throw new ApiError(response, json)
        })
      }
    })
}

export const create = (path, body) => request(path, 'POST', body)

export const read = (path) => request(path)

export const update = (path, body) => request(path, 'PATCH', body)

export const destroy = (path, body) => request(path, 'DELETE', body)

export const swapSortOrder = (path) => request(path, 'PATCH')

export const fetch = (
  { name, id, operation, params = {}, toPath, action, method },
  body
) => {
  let pathPrefix = toPath(params)

  if (pathPrefix.length > 0) {
    pathPrefix = `/${pathPrefix}`
  }

  body = JSON.stringify(body)

  switch (operation) {
    case 'list': {
      return read(`${pathPrefix}/${name}?${params.search || ''}`)
    }
    case 'custom': {
      return request(
        `${pathPrefix}/${name}/${action}?${params.search || ''}`,
        method,
        body
      )
    }
    case 'read': {
      if (id != null) {
        return read(`${pathPrefix}/${name}/${id}?${params.search || ''}`)
      } else {
        return read(`${pathPrefix}/${name}?${params.search || ''}`)
      }
    }
    case 'create': {
      return create(`${pathPrefix}/${name}`, body)
    }
    case 'update': {
      if (id != null) {
        return update(`${pathPrefix}/${name}/${id}`, body)
      } else {
        return update(`${pathPrefix}/${name}`, body)
      }
    }
    case 'destroy': {
      return destroy(`${pathPrefix}/${name}/${id}`, body)
    }
    case 'swapSortOrder': {
      return swapSortOrder(
        `${pathPrefix}/${name}/swap_sort_order/${params.a}/${params.b}`
      )
    }
    default: {
      throw new Error('Unknown operation')
    }
  }
}
