import {
  SIGN_IN_SUCCESS,
  SIGN_IN_ERROR,
  SIGN_OUT_SUCCESS,
} from '../actions/authorization'

export default (state = { currentUser: null, error: null }, action) => {
  const { type, payload } = action
  switch (type) {
    case SIGN_IN_SUCCESS:
      return {
        currentUser: payload,
        signedIn: true,
        error: null,
      }
    case SIGN_OUT_SUCCESS:
    case SIGN_IN_ERROR:
      return {
        currentUser: null,
        signedIn: false,
        error: payload ? payload.message : null,
      }
    default:
      return state
  }
}

export const currentUser = (state) => state.currentUser
export const error = (state) => state.error
