import React from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from 'styled-components'

import { getCurrentUser, isCableConnected } from '../../redux/reducers'

import Action from '../Action'
import Alert from '../Alert'
import Icon from '../Icon'

const CableDisconnectedAlert = () => {
  const theme = useTheme()
  const cableConnected = useSelector(isCableConnected)
  const currentUser = useSelector(getCurrentUser)

  const showAlert = currentUser != null && cableConnected === false

  if (showAlert) {
    console.warn('ActionCable disconnected!')
  }

  return (
    <Alert
      show={showAlert}
      actions={
        <>
          <Action
            color={theme.colors.brandPrimary}
            onClick={() => window.location.reload()}
          >
            Neu laden
          </Action>
        </>
      }
    >
      <Icon name="snooze" bordered={false} />
      <p>Verbindung wird wiederhergestellt...</p>
      <p>
        Bitte prüfen Sie die Internetverbindung oder laden Sie die App erneut.
      </p>
    </Alert>
  )
}

export default CableDisconnectedAlert
