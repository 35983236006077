import { transparentize } from 'polished'
import React from 'react'
import { render, unmountComponentAtNode } from 'react-dom'
import styled, { withTheme, ThemeProvider } from 'styled-components'

import { theme } from '../styles'

import Action from './Action'
import Icon from './Icon'

export const confirm = (prompt, icon = 'delete') => {
  const modalRoot = document.getElementById('modal-root')
  const container = document.createElement('div')
  modalRoot.appendChild(container)

  const promise = new Promise((resolve) => {
    render(
      <ThemeProvider theme={theme}>
        <Alert
          show
          actions={
            <>
              <Action onClick={() => resolve(false)}>
                <Icon name="cancel" />
              </Action>
              <Action onClick={() => resolve(true)}>
                <Icon name="confirm" />
              </Action>
            </>
          }
        >
          {icon && <Icon name={icon} bordered={false} />}
          <p>{prompt}</p>
        </Alert>
      </ThemeProvider>,
      container
    )
  })

  promise.finally(() => {
    unmountComponentAtNode(container)
    modalRoot.removeChild(container)
  })

  return promise
}

const Positioner = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) =>
    transparentize(0.2, theme.colors.background)};
`

const Container = styled.span`
  background: ${(props) => props.theme.colors.inactive};
  border-radius: 16px;
  width: 318px;
  text-align: center;
  font-size: ${(props) => props.theme.font.body};
  color: ${(props) => props.theme.colors.text};
  padding-top: 24px;
`

const Actions = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-evenly;
  align-items: center;
  align-self: stretch;
  margin: 8px 32px;
  padding: 8px;
`

const Alert = withTheme(({ actions, show, children }) => {
  return (
    <div>
      {show && (
        <Positioner>
          <Container>
            {children}
            <Actions>{actions}</Actions>
          </Container>
        </Positioner>
      )}
    </div>
  )
})

export default Alert
