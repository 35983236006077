import { call, put, takeEvery } from 'redux-saga/effects'

import {
  FETCH_RESOURCE_REQUEST,
  fetchResourceSuccess,
  fetchResourceFailure,
} from '../actions/resource'

import { addFlashMessage } from '../actions/flashMessage'

function* fetchResource(api, action) {
  const { resource, payload } = action
  let onSuccess, onError
  action.promise = new Promise((resolve, reject) => {
    onSuccess = resolve
    onError = reject
  })
  try {
    const result = yield call(api.fetch, resource, payload)
    yield put(fetchResourceSuccess(resource, result))
    onSuccess(result)
  } catch (error) {
    // console.error(error)
    yield put(fetchResourceFailure(resource, error))
    onSuccess(error)
    if (error.status !== 403) {
      yield put(addFlashMessage('FetchError', 'Fehler: ' + error))
    }
  }
}

export default function* watchFetch(api) {
  yield takeEvery(FETCH_RESOURCE_REQUEST, fetchResource, api)
}
