import Chart from 'chart.js'

export const extendLineChart = () => {
  // enable vertical line in Chart
  Chart.defaults.LineWithLine = Chart.defaults.line
  Chart.controllers.LineWithLine = Chart.controllers.line.extend({
    draw: function (ease) {
      Chart.controllers.line.prototype.draw.call(this, ease)
      if (this.chart.tooltip._active && this.chart.tooltip._active.length) {
        const activePoint = this.chart.tooltip._active[0]
        const ctx = this.chart.ctx
        const x = activePoint.tooltipPosition().x // +0.5 because it matches the grid lines better somehow,
        // maybe not that important because with real values the line won't be exactly on the grid line
        const topY = this.chart.scales['y-axis-0'].top
        const bottomY = this.chart.scales['y-axis-0'].bottom

        // draw line
        ctx.save()
        ctx.beginPath()
        ctx.moveTo(x, topY)
        ctx.lineTo(x, bottomY)
        ctx.lineWidth = 2
        ctx.strokeStyle = '#F00'
        ctx.stroke()
        ctx.restore()
      }
    },
  })
}

export const extendBarChartWithLine = () => {
  var originalLineDraw = Chart.controllers.horizontalBar.prototype.draw
  Chart.helpers.extend(Chart.controllers.horizontalBar.prototype, {
    draw: function (mouseXPos) {
      originalLineDraw.apply(this)

      const ctx = this.chart.ctx
      const topY = this.chart.scales['y-axis-0'].top
      const bottomY = this.chart.scales['y-axis-0'].bottom
      const leftX = this.chart.scales['x-axis-0'].left
      const rightX = this.chart.scales['x-axis-0'].right

      if (mouseXPos >= leftX && mouseXPos <= rightX) {
        // draw line
        ctx.save()
        ctx.beginPath()
        ctx.moveTo(mouseXPos, topY)
        ctx.lineTo(mouseXPos, bottomY)
        ctx.lineWidth = 2
        ctx.strokeStyle = '#000'
        ctx.stroke()
        ctx.restore()
      }
    },
  })
}
