import React from 'react'
import styled from 'styled-components'
import is from 'styled-is'

import Icon from '../../Icon'
import ProjectSwitcher from './ProjectSwitcher'

import Logo from '../../../../assets/images/logo.svg'

const Signet = styled(Logo)`
  height: 50px;
  align-self: flex-start;
`

const Header = styled.header`
  padding: 0 32px 0 16px;

  grid-area: header;
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid ${(props) => props.theme.colors.inactive};
  z-index: 1;
`

const MenuIcon = styled(Icon)`
  @media (min-width: 1440px) {
    display: none;
  }

  ${is('anonymous')`
    color: ${({ theme }) => theme.colors.background};
  `};
`

export default ({ isVisible, setVisible, anonymous }) => (
  <Header>
    <MenuIcon
      name="menu"
      onClick={() => setVisible(!isVisible)}
      anonymous={anonymous}
    />
    <ProjectSwitcher />
    <Signet />
  </Header>
)
