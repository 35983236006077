import React, { Component } from 'react'
import { Line } from 'react-chartjs-2'
import { optionsLineChart } from '../options'
import styled from 'styled-components'
import { extendLineChart } from '../../../../lib/chart_extensions'
import camelize from 'camelize'
import 'chartjs-plugin-zoom'

// TODO: add options to api
// TODO: move fetching data to hoc ("ChartDataProvider" for example)

const ChartWrapper = styled.div`
  height: 200px;
  margin-bottom: 50px;
`

export class LineChart extends Component {
  constructor(props) {
    super(props)
    this.state = {
      datasets: props.datasets,
      labels: props.labels,
      loading: false,
    }
  }

  componentDidMount = () => {
    this.fetchData()
    extendLineChart()
  }

  componentDidUpdate(prevProps, prevState) {
    const { startDate, endDate } = this.props
    if (prevProps.startDate !== startDate || prevProps.endDate !== endDate) {
      this.fetchData()
    }
  }

  fetchData = () => {
    const { dataUrl, token, startDate, endDate, onError } = this.props
    this.setState({ loading: true })

    window
      .fetch(`${dataUrl}&start_date=${startDate}&end_date=${endDate}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json; charset=UTF-8',
        },
      })
      .then(function (response) {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error('Error: Loading timeline data!')
        }
      })
      .then((response) => {
        this.dataReceived(response)
      })
      .catch((error) => {
        !onError && console.error('Error:', error)
        onError && onError(error)
      })
  }

  dataReceived = (data) => {
    const { datasets, labels } = camelize(data)
    this.setState({ datasets, labels, loading: false })
  }

  render() {
    const { key } = this.props

    const { datasets, labels } = this.state
    const data = { datasets, labels }

    return (
      <ChartWrapper>
        {data && (
          <Line
            id={key}
            className="line-chart"
            data={data}
            options={optionsLineChart}
            height={200}
          />
        )}
        {!data && <p>Keine Daten zum Anzeigen vorhanden.</p>}
      </ChartWrapper>
    )
  }
}

export default LineChart
