import { decode } from 'jsonwebtoken'

export const SIGN_IN = 'SIGN_IN'
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS'
export const SIGN_IN_ERROR = 'SIGN_IN_ERROR'

export const SIGN_OUT = 'SIGN_OUT'
export const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS'

export const signIn = (email, password) => ({
  type: SIGN_IN,
  payload: { email, password },
})

export const signOut = () => ({
  type: SIGN_OUT,
  payload: null,
})

export const signInSuccess = (token) => ({
  type: SIGN_IN_SUCCESS,
  payload: { ...decode(token), token },
})

export const signInError = (error) => ({
  type: SIGN_IN_ERROR,
  payload: error,
})

export const signOutSuccess = () => ({
  type: SIGN_OUT_SUCCESS,
  payload: null,
})
