import {
  WEB_PUSH_SUCCESS,
  WEB_PUSH_NOTIFICATION_RECEIVED,
} from '../actions/webPush'

export default (state = { enabled: false, notifications: [] }, action) => {
  const { enabled, notifications } = state
  const { type, payload } = action
  switch (type) {
    case WEB_PUSH_SUCCESS:
      return {
        enabled: payload,
        notifications,
      }
    case WEB_PUSH_NOTIFICATION_RECEIVED:
      return {
        enabled,
        notifications: [payload, ...notifications],
      }
    default:
      return {
        ...state,
      }
  }
}

export const getAllNotifications = (state) => state.notifications
export const enabled = (state) => state.enabled
