import React, { useState } from 'react'
import styled from 'styled-components'
import is from 'styled-is'

import Icon from '../Icon'

const Dropdown = styled.span`
  display: flex;
  align-items: center;
  flex: 0 1 200px;
  margin: 0 1em;

  ${is('formField')`
    position: relative;
    margin: 0px 0px 0.25rem 0px;
    padding: 2px;
    flex: auto;
  `};
`

const Label = styled.span`
  font-size: 18px;
  margin-right: 0.5em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  width: 171px;

  ${is('formField')`
    font-size: ${(props) => props.theme.font.body};
    width: inherit;
  `};
`

export const Options = styled.ul`
  position: absolute;
  top: 38px;
  list-style-type: none;
  margin: 0;
  padding: 0;
  background-color: ${(props) => props.theme.colors.background};
  border: 1px solid ${(props) => props.theme.colors.inactive};
  z-index: 1;

  & > li > a {
    display: inline-block;
    padding: 0.5em;
    width: 100%;
  }

  & > li:hover {
    background-color: ${(props) => props.theme.colors.brandPrimary}33;
  }

  will-change: transform;
  transition: transform 0.1s ease;
  transform: scaleY(0);

  ${is('open')`
    transform: scaleY(1);
  `};

  ${is('formField')`
    top: 28px;
  `};

  max-height: 350px;
  overflow-y: scroll;
`

const noop = () => undefined

export default ({
  options,
  selected,
  formField,
  onChange = noop,
  ...props
}) => {
  const [isOpen, setOpen] = useState(false)
  const label = options.find(({ id }) => id === selected)
  const select = (id) => {
    onChange(id)
    setOpen(false)
  }
  return (
    <Dropdown formField={formField} {...props}>
      <Label formField={formField}>{label?.name}</Label>
      <Icon
        name={isOpen ? 'up' : 'down'}
        small
        onClick={() => setOpen(!isOpen)}
      />
      <Options open={isOpen} formField={formField}>
        {options.map(({ id, name }, index) => (
          <li key={index}>
            <a onClick={() => select(id)}>{name}</a>
          </li>
        ))}
      </Options>
    </Dropdown>
  )
}
