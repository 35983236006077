import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

export const SET_CURRENT_PROJECT = 'SET_CURRENT_PROJECT'

export const setCurrentProject = (projectId) => ({
  type: SET_CURRENT_PROJECT,
  payload: projectId,
})

export const useSwitchProject = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  return (projectId, to) => (e) => {
    dispatch(setCurrentProject(projectId))
    history.push(to || `/projects/${projectId}`)
    e.stopPropagation()
  }
}
