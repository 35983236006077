import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import is from 'styled-is'

const centerable = css`
  ${is('center')`
    text-align: center;
  `};
`

const shared = css`
  font-weight: 600;
  margin: 0 0 1em;
`

export const H1 = styled.h1`
  ${centerable};
  ${shared};

  font-size: ${({ theme }) => theme.font.h1};
`

export const H2 = styled.h2`
  ${centerable};
  ${shared};

  font-size: ${({ theme }) => theme.font.h2};
`

export const H3 = styled.h3`
  ${centerable};
  ${shared};
  font-size: ${({ theme }) => theme.font.h3};
  margin: 1rem 0 0;

  ${is('pulled')`
    margin-left: -1rem;
  `}
`

export const Paragraph = styled.p`
  ${centerable};
`

export const Muted = styled.span`
  color: ${(props) => props.theme.colors.gray};
`

export const TextLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.text};
`
