export const FLASH_MESSAGE_ADD = 'FLASH_MESSAGE_ADD'
export const FLASH_MESSAGE_DISMISS_ALL = 'FLASH_MESSAGE_DISMISS_ALL'
export const FLASH_MESSAGE_DISMISS = 'FLASH_MESSAGE_DISMISS'

export const addFlashMessage = (type, message) => ({
  type: FLASH_MESSAGE_ADD,
  payload: {
    type,
    message,
  },
})

export const dismissFlashMessage = (messageId) => ({
  type: FLASH_MESSAGE_DISMISS,
  payload: {
    messageId,
  },
})

export const dismissAllFlashMessages = () => ({
  type: FLASH_MESSAGE_DISMISS_ALL,
  payload: {},
})
