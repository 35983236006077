import styled, { css } from 'styled-components'
import is, { isNot, match } from 'styled-is'

//import { inputCss } from './index'
import { Options } from './Dropdown'
import Icon from '../Icon'

const inputCss = css`
  width: 100%;
  border: none;
  line-height: 24px;
  margin: 0 0 3px;
  height: 27px;
  font-size: ${(props) => props.theme.font.body};

  outline: none;
  &:focus {
    border-bottom-color: ${(props) => props.theme.colors.brandPrimary};
    /* border-bottom-width: 2px; */
    /* margin-bottom: 2px; */
  }

  ${match('type', 'checkbox')`
    height: auto;
  `};

  /* ${({ error }) =>
    error &&
    css`
      background-color: ${({ theme }) =>
        transparentize(0.9, theme.colors.alert)};
    `}; */
`

export const SearchField = styled.input.attrs({
  type: 'search',
  placeholder: 'Search',
})`
  ${inputCss}
  height: 34px;
  padding: 0 15px;

  ${is('horizontal')`
    margin: 0.5rem 0;
  `};

  ${isNot('horizontal')`
    margin: 0 0 1rem;
  `};
`

export const SearchWrapper = styled.div`
  position: relative;
  color: ${({ theme }) => theme.colors.text};

  ${Options} {
    width: calc(100% - 34px);
    margin: 0 17px;
    background-color: ${({ theme }) => theme.colors.inactive};
  }
`

export const CloseIcon = styled(Icon).attrs({
  name: 'close',
  small: true,
  bordered: false,
})`
  position: absolute;
  top: 12px;
  right: 12px;
  color: ${({ theme }) => theme.colors.gray};
`

export const ClosableSearch = styled(SearchField).attrs({ type: 'text' })`
  flex: 0 0 50%;
  border-radius: 17px;
  margin: 3px 0;
`
