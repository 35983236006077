import { SET_CURRENT_PROJECT, SIGN_OUT_SUCCESS } from '../actions'

export default (state = null, action) => {
  const { type, payload } = action
  switch (type) {
    case SET_CURRENT_PROJECT:
      return payload
    case SIGN_OUT_SUCCESS:
      return null
    default:
      return state
  }
}
