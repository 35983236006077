import React from 'react'
import { createGlobalStyle, css, withTheme } from 'styled-components'

const brandPrimary = '#0F6E2D'
const brandSecondary = '#8CAF2D'

export const theme = {
  colors: {
    brandPrimary,
    brandSecondary,
    text: '#000',
    gray: '#979797',
    inactive: '#DFDFDF',
    lightGray: '#F1F1F1',
    background: '#FFFFFF',
    alert: '#F90505',
    critical: '#DE0000',
    warn: '#EF801A',
    success: '#0F6E2D',
    water: '#1C86FF',
    brandGradient: `linear-gradient(45deg, ${brandPrimary} 0%, ${brandSecondary} 100%)`,
  },
  font: {
    body: '14px',
    h1: '24px',
    h2: '18px',
    h3: '14px',
    small: '10px',
  },
}

export const fontSmoothing = css`
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html {
    font-size: ${theme.font.body};
    line-height: 1.5;
    background-color: ${theme.colors.background};
    color: ${theme.colors.text};
    min-height: 100%;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    font-family: 'Asap', sans-serif;
  }

  h1 {
    font-size: ${theme.font.h1};
  }

  h2 {
    font-size: ${theme.font.h2};
  }

  h3 {
    font-size: ${theme.font.h3};
  }

  html, body, main {
    height: 100%;
    margin: 0;
  }

  p {
    font-size: ${theme.font.body};
  }

  .react-datepicker__input-container {
    display: block;
  }
`

export const BrandGradient = withTheme(({ theme }) => (
  <linearGradient id="brandGradient" x1="0%" y1="0%" x2="100%" y2="0%">
    <stop offset="0%" stopColor={theme.colors.brandPrimary} />
    <stop offset="100%" stopColor={theme.colors.brandSecondary} />
  </linearGradient>
))
