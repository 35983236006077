import moment from 'moment'

const CHART_COLORS = {
  borderColor: 'rgb(255,255,255)',
  font: 'rgb(51,51,51)',
  gridLines: 'rgb(51,51,51)',
  zeroLineColor: 'rgb(102,102,255)',
}

export const defaultTimeFormat = 'DD.MM.YYYY HH:mm:ss'

const tooltipTimeFormat = defaultTimeFormat
const displayTimeFormats = {
  millisecond: 'HH:mm:ss.SSS',
  second: 'HH:mm:ss',
  minute: 'HH:mm',
  hour: '   D.MM., HH:mm', // spaces for spacing between adjacent labels
  day: 'D. MMM',
}

export const optionsLineChart = {
  responsive: true,
  maintainAspectRatio: false,
  elements: {
    point: {
      hitRadius: 5,
      radius: 0, // no points, only line
      borderColor: CHART_COLORS.borderColor,
      borderWidth: 3,
      hoverRadius: 0,
    },
    line: {
      fill: true,
      tension: 0.4, // Bézier curve tension (0 for no Bézier curves), default = 0.4, ~10% better performance without tension
    },
  },
  title: {
    display: false,
    text: 'Sensordaten - Chart',
    fontColor: CHART_COLORS.font,
    fontSize: 20,
    lineHeight: 1,
  },
  legend: {
    position: 'top',
    labels: {
      fontColor: CHART_COLORS.font,
      fontSize: 14,
      padding: 4,
    },
    onClick: null,
  },
  tooltips: {
    intersect: false,
    mode: 'index',
    titleFontSize: 18,
    bodyFontSize: 14,
    borderColor: CHART_COLORS.borderColor,
    borderWidth: 2,
  },
  hover: {
    intersect: false,
    mode: 'index',
    animationDuration: 400,
  },
  scales: {
    yAxes: [
      {
        position: 'left',
        gridLines: {
          color: CHART_COLORS.gridLines,
          zeroLineColor: CHART_COLORS.gridLines,
        },
      },
    ],
    xAxes: [
      {
        type: 'time',
        time: {
          tooltipFormat: tooltipTimeFormat,
          displayFormats: displayTimeFormats,
        },
        bounds: 'ticks',
        gridLines: {
          color: CHART_COLORS.gridLines,
        },
        ticks: {
          maxRotation: 20, // can be set to 0 if there shouldn't be rotated labels.
          // But long labels can override labels from neighbor ticks so rotating labels can help the visibility
          // Maybe the spacing with spaces is enough to handle that
        },
      },
    ],
  },
  pan: {
    enabled: true,
    mode: 'x',
    speed: 10,
    threshold: 10,
    rangeMin: {},
    rangeMax: {},
  },
  zoom: {
    enabled: true,
    // drag: false,
    // drag-to-zoom, if true, pan.enabled should be false and removeZoom() (with a button presumably?) has to be added
    // example for drag-to-zoom (not with chartjs): https://jsfiddle.net/4w626v2s/2/
    mode: 'x',
    rangeMin: {},
    rangeMax: {},
  },
}
