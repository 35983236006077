import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import is from 'styled-is'

import { useWaitingMonitor } from './util'
import { Emoji, Svg } from './Icon'

export const ExternalLink = ({ children, href, ...props }) => (
  <a href={href} target="_blank" rel="noopener noreferrer" {...props}>
    {children}
  </a>
)

const LinkOrButton = ({
  children,
  to,
  onClick,
  external,
  waiting,
  ...props
}) => {
  const Tag = external ? ExternalLink : to ? Link : 'button'

  // https://github.com/styled-components/styled-components/issues/1198
  delete props.vertical
  delete props.horizontal

  return (
    <Tag onClick={onClick} to={to} {...props}>
      {children}
    </Tag>
  )
}

export const Actions = styled.div`
  display: flex;
  align-items: center;

  > :not(:last-child) {
    margin-right: 1rem;
  }
`

export const StyledAction = styled(LinkOrButton)`
  margin: 0;
  padding: 0;
  font-size: 1rem;

  background-color: transparent;
  border: none;
  cursor: pointer;

  display: inline-flex;
  flex-direction: column;
  align-items: center;

  text-decoration: none;
  color: ${(props) => props.color || props.theme.colors.background};

  ${is('vertical')`
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  `};

  ${is('horizontal')`
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  `};

  ${is('waiting')`
    ${Emoji}, ${Svg} {
      stroke: ${(props) => props.color || props.theme.colors.background};
      background: ${(props) => props.color || props.theme.colors.brandGradient};
    }
  `};
`

export default ({ onClick, ...props }) => {
  const [waiting, onChangeMonitored] = useWaitingMonitor(onClick)

  return (
    <StyledAction {...props} onClick={onChangeMonitored} waiting={waiting} />
  )
}
