import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'

import { projects } from '../../../redux/actions/resource'
import { setCurrentProject } from '../../../redux/actions/currentProject'

import {
  getCurrentProjectId,
  getAllProjects,
  getCurrentUser,
} from '../../../redux/reducers'

import Dropdown from '../../forms/Dropdown'
import Plantection from '../../../../assets/images/plantection.svg'

const Img = styled.img`
  height: 16px;
`

class ProjectSwitcher extends Component {
  componentDidMount() {
    this.fetchProjects(null)
  }

  componentDidUpdate(prevProps) {
    this.fetchProjects(prevProps.currentUser)
  }

  fetchProjects(prevUser) {
    const { currentUser, fetchProjects } = this.props
    if (currentUser && currentUser !== prevUser) {
      fetchProjects()
    }
  }

  hasProjectSwitcher = () => {
    const {
      currentUser,
      location: { pathname },
    } = this.props

    return (
      currentUser &&
      ![
        '/dashboard',
        '/users',
        '/projects',
        '/profile',
        '/notifications',
      ].reduce(
        (showSwitcher, route) => showSwitcher || route.startsWith(pathname),
        false
      )
    )
  }

  render() {
    const { currentProjectId, projects, setCurrentProject } = this.props

    return this.hasProjectSwitcher() ? (
      <>
        <Dropdown
          options={projects}
          selected={currentProjectId}
          onChange={setCurrentProject}
        />
      </>
    ) : (
      <>
        <Plantection />
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  currentProjectId: getCurrentProjectId(state),
  projects: getAllProjects(state),
  currentUser: getCurrentUser(state),
})

const mapDispatchToProps = (dispatch) => ({
  fetchProjects: () => dispatch(projects.list()),
  setCurrentProject: (id) => dispatch(setCurrentProject(id)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProjectSwitcher))
