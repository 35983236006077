import { v4 as uuid } from 'uuid'
import {
  FLASH_MESSAGE_ADD,
  FLASH_MESSAGE_DISMISS_ALL,
  FLASH_MESSAGE_DISMISS,
} from '../actions/flashMessage'
import { FETCH_RESOURCE_FAILURE } from '../actions/resource'

const initialState = {
  all: [],
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case FLASH_MESSAGE_ADD:
      return {
        all: [
          ...state.all,
          {
            id: uuid(),
            ...payload,
          },
        ],
      }
    case FLASH_MESSAGE_DISMISS:
      return {
        all: state.all.filter((fm) => fm.id !== payload.messageId),
      }
    case FLASH_MESSAGE_DISMISS_ALL:
      return {
        all: [],
      }
    default:
      return state
  }
}

export const getAll = (state) => state.all
export const getByType = (state, type) =>
  getAll(state).filter((f) => f.type === type)
