import React from 'react'

import styled, { keyframes } from 'styled-components'
import is from 'styled-is'

import { useSelector } from 'react-redux'
import { getPendingResourceCount } from '../../redux/reducers'

export const loading = (width = '100vw') => keyframes`
  0% {
    transform: translateX(1rem);
  }
  50% {
    transform: translateX(calc(${width} - 100% - 1rem));
  }
  100% {
    transform: translateX(1rem);
  }
`

const PendingResources = styled.div`
  grid-area: pending;
  display: flex;
  align-items: flex-start;
  margin: 0.3rem 0;
`

const LoadingIndicator = styled.div`
  height: 3px;
  border-radius: 1.5px;
  width: 20%;
  display: none;
  ${is('visible')`
    display: inline-block;
    background-image: ${({ theme }) => theme.colors.brandGradient};
    animation: ${loading()} 3s ease-in-out infinite;
    @media (min-width: 1440px) {
      // subtract open nav
      animation: ${loading('100vw - 375px')} 3s ease-in-out infinite;
    }
  `};
`

export default () => {
  const pendingResources = useSelector(getPendingResourceCount)
  return (
    <PendingResources>
      <LoadingIndicator visible={pendingResources > 0} />
    </PendingResources>
  )
}
