import { compile } from 'path-to-regexp'

export const FETCH_RESOURCE_REQUEST = 'FETCH_RESOURCE_REQUEST'
export const FETCH_RESOURCE_SUCCESS = 'FETCH_RESOURCE_SUCCESS'
export const FETCH_RESOURCE_FAILURE = 'FETCH_RESOURCE_FAILURE'

const requestAction = (operation, resource, payload) => {
  const actionObject = {
    type: FETCH_RESOURCE_REQUEST,
    resource: {
      ...resource,
      operation,
    },
    payload,
  }

  return actionObject
}

const defaultOptions = {
  readonly: false,
  singular: false,
  nesting: '',
  shallow: true,
  sortable: false,
}

const emptyPath = () => ''

/**
 *
 * @param {String} name
 * @param {defaultOptions} options
 */
export const createResource = (name, options = defaultOptions) => {
  const { readonly, singular, nesting, shallow, sortable } = Object.assign(
    {},
    defaultOptions,
    options
  )

  const toPath = compile(nesting)

  const resourceActions = Object.assign(
    { __options: { readonly, singular, nesting, shallow, sortable } },
    {
      read: (id, params = {}) =>
        requestAction('read', {
          name,
          id,
          params,
          toPath: shallow ? emptyPath : toPath,
        }),
      custom: (action, method, params = {}, attributes) =>
        requestAction(
          'custom',
          {
            action,
            method,
            name,
            params,
            toPath,
          },
          attributes
        ),
    },
    singular
      ? null
      : {
          list: (params = {}) => {
            //console.log('list', name, params, toPath)
            return requestAction('list', { name, params, toPath })
          },
        },
    readonly
      ? null
      : {
          create: (params = {}, attributes) =>
            requestAction('create', { name, params, toPath }, attributes),
          update: (id, params = {}, attributes) =>
            requestAction(
              'update',
              { name, id, params, toPath: shallow ? emptyPath : toPath },
              attributes
            ),
          destroy: (id, params = {}) =>
            requestAction('destroy', {
              name,
              id,
              params,
              toPath: shallow ? emptyPath : toPath,
            }),
        },
    sortable
      ? {
          swapSortOrder: (params) =>
            requestAction('swapSortOrder', { name, params, toPath }),
        }
      : null
  )

  return resourceActions
}

export const fetchResourceSuccess = (resource, payload, isPush = false) => ({
  type: FETCH_RESOURCE_SUCCESS,
  resource,
  payload,
  isPush,
})

export const fetchResourceFailure = (resource, payload) => ({
  type: FETCH_RESOURCE_FAILURE,
  resource,
  payload,
})

const moduleNesting = { nesting: 'central_modules/:moduleId' }
const programNesting = { nesting: 'programs/:programId' }
const projectNesting = { nesting: 'projects/:projectId' }

export const projects = createResource('projects')
export const programs = createResource('programs', {
  ...projectNesting,
  sortable: true,
})
export const programPublishAttempts = createResource('publish', {
  ...programNesting,
  shallow: true,
})
export const valveGroups = createResource('valve_groups', programNesting)
export const valves = createResource('valves', projectNesting)
export const modules = createResource('modules', projectNesting)

export const replaceCentralModule = createResource(
  'replace_module',
  moduleNesting
)
export const shifts = createResource('shifts', programNesting)
export const shiftPublishAttempts = createResource('publish', {
  nesting: 'shifts/:shiftId',
  shallow: true,
})
export const notifications = createResource('notifications')
export const password = createResource('password', { singular: true })
export const dashboard = createResource('dashboard', { readonly: true })
export const profile = createResource('profile', { singular: true })
export const sensorValues = createResource('sensor_values', projectNesting)
export const search = createResource('search', {
  ...projectNesting,
  shallow: false,
})
export const temporarySuspensions = createResource('temporary_suspensions')
