import React from 'react'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Muted } from '../components/type'

import { devices } from './responsive/responsive'

const Outer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;
`
const headerHeight = '80px'
const footerHeight = '30px'

const Inner = styled.div`
  width: calc(100% - 60px);
  height: calc(100% - ${headerHeight} - ${footerHeight});

  @media ${devices.hd} and (min-height: 1000px) {
    width: 1058px;
    height: 644px;
  }

  background-color: ${(props) => props.theme.colors.background};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);

  padding: 20px;

  display: grid;
  grid-template-rows: 20px 1fr;
  grid-template-columns: 40px 1fr 40px;
  grid-template-areas:
    'hint hint actions'
    '- content actions';
`

const Area = styled.div`
  grid-area: ${(props) => props.area};
`

const Content = styled(Area).attrs({ area: 'content' })`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const Modal = ({ t, actions, hint = t('editMode'), children }) => (
  <Outer>
    <Inner>
      <Area area="hint">
        <Muted>{hint}</Muted>
      </Area>
      <Content>{children}</Content>
      <Area area="actions">{actions}</Area>
    </Inner>
  </Outer>
)

export default withTranslation()(Modal)
