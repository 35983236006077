import PropTypes from 'prop-types'
import React from 'react'
import styled, { css, withTheme } from 'styled-components'
import is from 'styled-is'

const context = require.context('../../assets/icons', false, /\.svg$/)
const icons = context.keys().reduce((map, key) => {
  const name = key.replace(/.*\.\/(.*)\.svg$/, '$1')
  map[name] = context(key).default
  return map
}, {})

const shared = css`
  width: 32px;
  height: 32px;

  svg {
    width: 32px;
    height: 32px;
  }

  ${is('small')`
    width: 16px;
    height: 16px;

    svg {
      width: 16px;
      height: 16px;
    }
  `};

  ${is('text')`
    margin: 0 0.25em;
    vertical-align: middle;

    width: 1em;
    height: 1em;

    svg {
      width: 1em;
      height: 1em;
    }
  `};

  ${is('bordered')`
    border-style: solid;
    border-width: 1px;
    border-color: ${(props) => props.border || props.theme.colors.brandPrimary};
    border-radius: 50%;

    width: 40px;
    height: 40px;

    svg {
      width: 20px;
      height: 20px;
    }

    ${is('small')`
      width: 20px;
      height: 20px;

      svg {
        width: 10px;
        height: 10px;
      }
    `};
  `};

  ${is('background')`
    background-color: ${(props) => props.background};
  `};

  ${is('onClick')`
    cursor: pointer;
  `};

  ${is('solid')`
    cursor: pointer;
    border-radius: 50px;
    border-style: solid;
    border-width: 1px;
    border-color: ${(props) => props.border};
  `};

  ${is('rotate')`
    transform: rotate(${({ rotate }) => `${rotate}deg`});
  `}
`

export const Container = styled.span`
  ${shared};

  display: inline-flex;
  align-items: center;
  justify-content: center;

  color: ${(props) => props.color};
`

export const Svg = styled.svg`
  stroke: currentColor;
`

export const Emoji = styled.span`
  ${shared};

  line-height: 40px;
  text-align: center;
`

const aliases = {
  add: 'plus',
  back: 'chevron-left',
  bell: 'bell-on',
  cancel: 'times',
  close: 'times',
  confirm: 'check',
  delete: 'trash-alt',
  down: 'chevron-down',
  edit: 'pen',
  forward: 'arrow-right',
  list: 'list-ul',
  location: 'map-marker-alt',
  logout: 'sign-out',
  manual: 'hand-paper',
  menu: 'bars',
  module: 'cube',
  remove: 'trash-alt',
  tiles: 'grip-horizontal',
  up: 'chevron-up',
  valve: 'sprinkler',
  view: 'eye',
}

const aliasOrName = (name) => aliases[name] || name

const BORDERLESS = [
  'module',
  'valve',
  'menu',
  'bell',
  'exclamation-circle',
  'check-circle',
  'info-circle',
]
const SMALL = ['module', 'valve', 'menu', 'bell']

const Icon = ({
  name,
  onClick,
  theme,
  color = BORDERLESS.includes(name)
    ? theme.colors.brandPrimary
    : theme.colors.text,
  bordered = !BORDERLESS.includes(name),
  border,
  background,
  small = SMALL.includes(name),
  text,
  rotate,
  ...props
}) => {
  const svg = icons[aliasOrName(name)]
  return (
    <Container
      onClick={onClick}
      color={color}
      bordered={bordered}
      background={background}
      small={small}
      text={text}
      border={border}
      rotate={rotate}
      {...props}
    >
      <Svg viewBox={svg.viewBox}>
        <use xlinkHref={`#${svg.id}`} />
      </Svg>
    </Container>
  )
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  background: PropTypes.string,
  onClick: PropTypes.func,
  small: PropTypes.bool,
  rotate: PropTypes.number,
}

export const names = Object.keys(icons)
export default withTheme(Icon)
