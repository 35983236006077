import { useState } from 'react'

export const useWaitingMonitor = (promiseHandler) => {
  const [waiting, setWaiting] = useState(false)

  const monitoredPromiseHandler = (e) => {
    if (waiting || !promiseHandler) {
      return console.warn('Ignoring onChange', waiting, promiseHandler)
    }

    const request = promiseHandler(e)
    const promise =
      request == null || request instanceof Promise ? request : request.promise

    if (promise != null && request instanceof Promise) {
      setWaiting(true)
      promise.finally(() => setWaiting(false))
    }
  }

  return [waiting, monitoredPromiseHandler]
}
