export const WEB_PUSH_REQUEST = 'WEB_PUSH_REQUEST'
export const WEB_PUSH_SUCCESS = 'WEB_PUSH_SUCCESS'
export const WEB_PUSH_FAILURE = 'WEB_PUSH_FAILURE'
export const WEB_PUSH_NOTIFICATION_RECEIVED = 'WEB_PUSH_NOTIFICATION_RECEIVED'

export const webPushRequest = (subscribe) => ({
  type: WEB_PUSH_REQUEST,
  payload: subscribe,
})

export const webPushSuccess = (subscribed) => ({
  type: WEB_PUSH_SUCCESS,
  payload: subscribed,
})

export const webPushFailure = (payload) => ({
  type: WEB_PUSH_FAILURE,
  payload,
})

export const notificationReceived = (payload) => ({
  type: WEB_PUSH_NOTIFICATION_RECEIVED,
  payload,
})
