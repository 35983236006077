import React from 'react'
import styled, { css, keyframes } from 'styled-components'
import is, { match } from 'styled-is'

import { useWaitingMonitor } from '../util'

const Outer = styled.div`
  display: inline-flex;
  align-items: center;

  width: 40px;
  height: 20px;
  border-radius: 10px;
  border: 1px solid currentColor;
  padding: 3px;

  ${is('onClick')`
    cursor: pointer;
  `};

  ${is('asForm')`
    margin-bottom: 0.5rem;
  `};
`

const rotate = keyframes`
  from {
    transform: translate(10px, 0) rotate(0deg);
  }

  to {
    transform: translate(10px, 0) rotate(360deg);
  }
`

const intermediate = css`
  border-radius: 0;
  height: 0;
`

const Inner = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 1px solid currentColor;

  transition: all 0.5s;

  ${is('waiting')`
    ${intermediate};
    animation: ${rotate} 1s linear infinite;
  `};

  ${match('checked', undefined)`
    ${intermediate};
    transform: translate(10px, 0);
  `};

  ${match('checked', true)`
    background-color: currentColor;
    transform: translate(20px, 0);
  `};
`

export const Toggle = ({ checked, onChange, asForm, disabled }) => {
  const [waiting, onChangeMonitored] = useWaitingMonitor(onChange)

  return (
    <Outer asForm={asForm} onClick={disabled ? null : onChangeMonitored}>
      <Inner checked={checked} waiting={waiting} />
    </Outer>
  )
}

export default Toggle

const Disabled = styled.div`
  color: ${(props) => props.theme.colors.gray};
`
const Description = styled.p`
  font-size: ${(props) => props.theme.font.body};
  margin: 0.25rem 0px;
`

export const ToggleState = ({ checked, description }) => {
  return (
    <Disabled>
      <Toggle checked={checked == null ? false : checked} disabled />
      <Description> {description} </Description>
    </Disabled>
  )
}
