import isValid from 'date-fns/isValid'
import parseISO from 'date-fns/parseISO'

import { transparentize } from 'polished'

import React, { Component, useState, useEffect, forwardRef } from 'react'
import UnstyledDatePicker from 'react-datepicker'
import { Field } from 'react-final-form'
import { Translation, useTranslation } from 'react-i18next'

import styled, { css } from 'styled-components'
import is, { match, isNot } from 'styled-is'

import { Muted } from '../type'
import StandardDropdown from './Dropdown'
import Icon from '../Icon'
import Toggle from './Toggle'
import Layout from '../Layout'

export * from './buttons'
export * from './Search'
export * from './Toggle'

export const StyledForm = styled.form`
  display: grid;
  grid-template-columns: ${(props) =>
    props.singleColumn ? '1fr' : 'minmax(200px, 768px)'};
  grid-gap: 2rem;
  justify-content: center;
  margin: 0px 1.5rem;

  @media (min-width: 768px) {
    grid-template-columns: ${(props) =>
      props.singleColumn ? '1fr' : 'minmax(200px, 768px)'};
    max-width: 1600px;
    margin: 0 auto;
  }
`

export const FormGroup = styled.div`
  display: flex;
  ${is('hidden')`
    display: none;
  `};
  flex-direction: column-reverse;
  font-size: 14px;

  margin-bottom: 0.5rem;

  ${is('rowWidth')`
    width: ${(props) => props.rowWidth};
  `};
`

export const FormGroupRow = styled(FormGroup)`
  flex-direction: row;
  align-items: center;
`

export const FormRow = styled.div`
  display: flex;
`

export const RowSpace = styled.span`
  width: ${(props) => props.rowWidth};
`

export const Label = styled.label`
  color: ${(props) => props.theme.colors.gray};
`

export const inputCss = css`
  width: 100%;
  border: none;
  line-height: 24px;
  margin: 0 0 3px;
  height: 27px;
  font-size: ${(props) => props.theme.font.body};

  outline: none;
  &:focus {
    border-bottom-color: ${(props) => props.theme.colors.brandPrimary};
    /* border-bottom-width: 2px; */
    /* margin-bottom: 2px; */
  }

  ${match('type', 'checkbox')`
    height: auto;
  `};

  /* ${({ error }) =>
    error &&
    css`
      background-color: ${({ theme }) =>
        transparentize(0.9, theme.colors.alert)};
    `}; */
`

export const InputField = styled.div`
  ${isNot('noLine')`
    border-bottom: 1px solid ${(props) => props.theme.colors.gray};
  `};

  ${is('icon')`
    display: flex;
    align-items: center;
  `};
`

export const InputWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;

  > .react-datepicker-wrapper {
    flex: 1;
  }
`

const ValidationWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  flex: 1;
`

const FormFieldError = styled.span`
  color: ${({ theme }) => theme.colors.alert};
  font-size: ${({ theme }) => theme.font.small};
  font-weight: 600;
`

const Suffix = styled.div`
  margin: 0 0.5rem 3px;
`

export const Input = styled(Field).attrs((props) => ({
  component: props.component || 'input',
}))`
  ${inputCss};
  width: 100%;
`

export const PlainInput = styled.input`
  ${inputCss};
`

export const FakeInput = styled.div`
  ${inputCss};

  display: flex;
  justify-content: space-around;
  align-items: stretch;
`

const DatePicker = styled(UnstyledDatePicker)`
  ${inputCss};
`

export const Dropdown = ({ input: { value, onChange }, meta, ...props }) => {
  const [selected, setSelected] = useState(value)
  useEffect(() => setSelected(value), [value])

  const onChangeSelected = (id) => {
    onChange(id)
    setSelected(id)
  }

  return (
    <StandardDropdown
      formField
      onChange={onChangeSelected}
      selected={selected}
      {...props}
    />
  )
}

const CustomPickerInput = forwardRef(({ value, onClick, onChange }, ref) => {
  const [dateValue, setDateValue] = useState(value)
  const [isTyping, setIsTyping] = useState(false)

  useEffect(() => {
    if (!isTyping) {
      setDateValue(value)
    }
    if (value != dateValue) {
      setIsTyping(false)
    }
  }, [value, isTyping, dateValue])

  const handleOnChange = (e) => {
    setDateValue(e.target.value)
    setIsTyping(true)
    onChange(e)
  }
  return (
    <InputWrapper>
      <PlainInput
        type="input"
        onClick={onClick}
        value={dateValue}
        onChange={handleOnChange}
        ref={ref}
      />
      <Icon small name="calendar" onClick={onClick} />
    </InputWrapper>
  )
})

const ModuleSearch = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  height: 500px;
  z-index: 2;
`

export const ModuleSearchField = ({ input: { onChange }, meta, module }) => {
  const [name, setName] = useState(module?.name)
  const [showSearch, setShowSearch] = useState(false)

  const handleClick = () => {
    console.log('click')
    setShowSearch(true)
  }

  const handleResult = (module) => {
    setName(moduel?.name)
    onChange(module.id)
  }
  return (
    <>
      {showSearch && (
        <ModuleSearch
          handleResult={handleResult}
          onClick={() => setShowSearch(false)}
        >
          <Layout>
            <p> Suche</p>
          </Layout>
        </ModuleSearch>
      )}
      <InputWrapper>
        <PlainInput type="input" onClick={handleClick} value={name} readOnly />
        <Icon small name="search" onClick={handleClick} />
      </InputWrapper>
    </>
  )
}

export const FormToggle = ({ input: { value, onChange }, meta }) => {
  const [enabled, setEnabled] = useState(value)
  useEffect(() => setEnabled(value), [value])

  const onChangeSelected = () => {
    const toggledEnable = !enabled
    onChange(toggledEnable)
    setEnabled(toggledEnable)
  }

  return <Toggle asForm checked={enabled} onChange={onChangeSelected} />
}

export const DateTime = ({
  input: { value, values, onChange, ...input },
  meta,
  ...props
}) => {
  const { submitError } = meta
  const { t, i18n } = useTranslation()
  value = parseISO(value)
  if (!isValid(value)) {
    value = null
  }

  const setDate = (date) => {
    onChange(date.toISOString())
  }

  return (
    <ValidationWrapper>
      <DatePicker
        error={submitError}
        autoComplete="off"
        locale={i18n.language}
        selected={value}
        onChange={setDate}
        {...input}
        {...props}
        dateFormat={t('dateTimeFormat')}
        timeFormat={t('timeFormat')}
        timeIntervals={15}
        timeCaption={t('time')}
        showTimeSelect
        customInput={<CustomPickerInput />}
      />
      {submitError && <FormFieldError>{submitError}</FormFieldError>}
    </ValidationWrapper>
  )
}

export class Weekdays extends Component {
  handleChange = (e) => {
    let { value } = this.props.input
    if (!Array.isArray(value)) {
      value = []
    }
    if (e.target.checked) {
      this.props.input.onChange([...value, e.target.value])
    } else {
      const index = value.indexOf(e.target.value)
      if (index > -1) {
        this.props.input.onChange([
          ...value.slice(0, index),
          ...value.slice(index + 1),
        ])
      }
    }
  }

  render() {
    let { value } = this.props.input
    if (!Array.isArray(value)) {
      value = []
    }
    return (
      <FakeInput>
        {[
          'monday',
          'tuesday',
          'wednesday',
          'thursday',
          'friday',
          'saturday',
          'sunday',
        ].map((weekday) => (
          <label key={weekday}>
            <input
              type="checkbox"
              value={weekday}
              checked={value.indexOf(weekday) >= 0}
              onChange={this.handleChange}
            />
            <Muted>
              <Translation>{(t) => t(`weekdays.${weekday}`)}</Translation>
            </Muted>
          </label>
        ))}
      </FakeInput>
    )
  }
}

export const FormField = ({
  name,
  label,
  type,
  icon,
  suffix,
  rowWidth,
  noLine,
  ...props
}) => (
  <FormGroup hidden={type === 'hidden'} rowWidth={rowWidth}>
    <Label htmlFor={name}>{label}</Label>
    <InputField icon={icon != null} noLine={noLine}>
      <InputWrapper>
        <Input id={name} name={name} type={type} {...props} />
        {suffix && <Suffix>{suffix}</Suffix>}
      </InputWrapper>
      {icon}
    </InputField>
  </FormGroup>
)

export const ValidationError = styled.div`
  color: red;
  grid-column-end: span 2;
  margin-bottom: 2rem;
`
