import {
  FETCH_RESOURCE_REQUEST,
  FETCH_RESOURCE_SUCCESS,
  FETCH_RESOURCE_FAILURE,
} from '../actions/resource'

export default (state = 0, action) => {
  const { type, isPush } = action
  if (!isPush) {
    switch (type) {
      case FETCH_RESOURCE_REQUEST:
        return state + 1
      case FETCH_RESOURCE_SUCCESS:
      case FETCH_RESOURCE_FAILURE:
        return state - 1
      default:
        return state
    }
  } else {
    return state
  }
}

export const count = (state) => state
