export const USER_CHANNEL_CONNECTED = 'USER_CHANNEL_CONNECTED'
export const USER_CHANNEL_DISCONNECTED = 'USER_CHANNEL_DISCONNECTED'
export const USER_CHANNEL_CONNECTION_ERROR = 'USER_CHANNEL_CONNECTION_ERROR'

export const userChannelConnected = () => ({
  type: USER_CHANNEL_CONNECTED,
  payload: null,
})

export const userChannelDisconnected = () => ({
  type: USER_CHANNEL_DISCONNECTED,
  payload: null,
})

export const userChannelConnectionError = (payload) => ({
  type: USER_CHANNEL_CONNECTION_ERROR,
  payload: { message: payload.message },
})
