import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'

import authorization, * as fromAuthorization from './authorization'
import resources, * as fromResources from './resource'
import pendingResources, * as fromPendingResources from './pendingResources'
import currentProjectId from './currentProject'
import flashMessage, * as fromFlashMessage from './flashMessage'
import webPush, * as fromWebPush from './webPush'
import cable, * as fromCable from './cable'

const sortByName = (a, b) => {
  if (a.name < b.name) {
    return -1
  }
  if (a.name > b.name) {
    return 1
  }
  return 0
}

const newestFirst = (a, b) => b.id - a.id

export const arrayFilter = (array, contained = true) => (model) =>
  ((array || []).find((id) => id === model.id) != null) === contained

export default (history) =>
  combineReducers({
    authorization,
    router: connectRouter(history),
    resources,
    pendingResources,
    currentProjectId,
    flashMessage,
    cable,
    webPush,
  })

export const getCurrentUser = (state) =>
  fromAuthorization.currentUser(state.authorization)
export const getSignInError = (state) =>
  fromAuthorization.error(state.authorization)

export const getAllProjects = (state) =>
  fromResources.projects.getAll(state.resources)
export const getProjectById = (state, id) =>
  fromResources.projects.getById(state.resources, id)

export const getCurrentProjectId = (state) =>
  state.currentProjectId || getStartProjectSetting(state)?.id
export const getCurrentProject = (state) =>
  getProjectById(state, getCurrentProjectId(state))

export const getAllPrograms = (state) =>
  fromResources.programs.getAll(state.resources)
export const getProgramById = (state, id) =>
  fromResources.programs.getById(state.resources, id)

export const getAllValveGroups = (state, filter, sort) =>
  fromResources.valveGroups.getAll(state.resources, { filter, sort })
export const getValveGroupById = (state, id) =>
  fromResources.valveGroups.getById(state.resources, id)

export const getAllValves = (state, filter, sort = sortByName) =>
  fromResources.valves.getAll(state.resources, { filter, sort })
export const getValveById = (state, id) =>
  fromResources.valves.getById(state.resources, id)
export const findValveByTopic = (state, topic) =>
  getAllValves(state).find((valve) => valve.mqttTopic === topic)

export const getAllModules = (state, filter, sort) =>
  fromResources.modules.getAll(state.resources, { filter, sort })
export const getModuleById = (state, id) =>
  fromResources.modules.getById(state.resources, id)

export const getAllShifts = (state, options) =>
  fromResources.shifts.getAll(state.resources, options)
export const getShiftById = (state, id) =>
  fromResources.shifts.getById(state.resources, id)
export const getAllShiftsForProgram = (state, id) =>
  fromResources.shifts.getAll(state.resources, {
    filter: ({ programId }) => programId === id,
  })

export const getAllSensorValues = (state, filter, sort = sortByName) =>
  fromResources.sensorValues.getAll(state.resources, { filter, sort })
export const getSensorValueById = (state, id) =>
  fromResources.sensorValues.getById(state.resources, id)

export const getDashboard = (state) =>
  fromResources.dashboard.getAll(state.resources)

export const getAllPublishAttempts = (state) =>
  fromResources.publishAttempts.getAll(state.resources, { sort: newestFirst })

export const getCollectionMeta = (state, collection, key) =>
  fromResources[collection].getCollectionMeta(state.resources, key)

export const getAllFlashMessages = (state) =>
  fromFlashMessage.getAll(state.flashMessage)
export const getErrorMessages = (state) =>
  fromFlashMessage.getByType(state.flashMessage, 'error')

export const getPendingResourceCount = (state) =>
  fromPendingResources.count(state.pendingResources)

export const webPushEnabled = (state) => fromWebPush.enabled(state.webPush)
export const getAllWebPushNotifications = (state) =>
  fromWebPush.getAllNotifications(state.webPush)

export const getAllNotifications = (state) =>
  fromResources.notifications.getAll(state.resources, { sort: newestFirst })
export const getNotificationById = (state, id) =>
  fromResources.notifications.getById(state.resources, id)
export const getUnreadNotificationCount = (state) =>
  fromResources.notifications
    .getAll(state.resources)
    .filter(({ read }) => !read).length

export const getProfile = (state) =>
  fromResources.profile.getById(state.resources, getCurrentUser(state)?.sub)

const getSetting = (state, setting) =>
  (getProfile(state)?.settings || {})[setting]

export const getStartProjectSetting = (state) => {
  const startProjectId = getSetting(state, 'startProjectId')
  if (startProjectId != null) {
    return getProjectById(state, startProjectId)
  } else {
    const projectIds = getProfile(state)?.projectIds || []
    return getProjectById(state, projectIds[0])
  }
}

export const getLanguageSetting = (state) =>
  getSetting(state, 'language') || navigator.language.split('-')[0]

export const getUseTilesSetting = (state) => {
  const useTiles = getSetting(state, 'useTiles')
  if (useTiles != null) {
    return useTiles
  } else {
    return true
  }
}

export const getNotificationSettings = (state) => ({
  email: getSetting(state, 'emailNotificationsEnabled'),
  sms: getSetting(state, 'smsNotificationsEnabled'),
  push: webPushEnabled(state),
})

export const isCableConnected = (state) => fromCable.isConnected(state.cable)

export const getCurrentRole = (state) => {
  const profile = getProfile(state)
  const project = getCurrentProject(state)
  if (project != null && profile != null && profile?.projectRoles != null) {
    const projectRole = profile.projectRoles.find(
      (role) => role.projectId === project.id
    )
    return projectRole && projectRole.role
  } else {
    // no role found
    return null
  }
}
export const getMapSearch = (state) =>
  fromResources.search.getById(state.resources, 'map')
