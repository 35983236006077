import { decode } from 'jsonwebtoken'

import { create } from './api'

const MINUTE = 60e3

export const getStoredToken = () =>
  JSON.parse(window.localStorage.getItem('authToken'))
export const storeToken = (token) => {
  window.localStorage.setItem('authToken', JSON.stringify(token))
}
export const removeAuthToken = () => storeToken(null)

export const expiresIn = (token) =>
  decode(token).exp * 1000 - new Date().getTime() - 1 * MINUTE
export const issuedAt = (token) => {
  return decode(token).exp * 1000 - 60 * 24 * MINUTE
}
export const tokenAgeInSeconds = (token) => {
  return (new Date().getTime() - issuedAt(token)) / 1000
}

export const authorize = async (tokenOrCredentials) => {
  const { payload: auth } = tokenOrCredentials
  if (auth && auth.email && auth.password) {
    const {
      json: { jwt },
    } = await create('/user_token', JSON.stringify({ auth }))
    tokenOrCredentials = jwt
  }

  if (expiresIn(tokenOrCredentials) > 0) {
    return tokenOrCredentials
  } else {
    return null
  }
}
