import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import styled, { withTheme } from 'styled-components'
import is from 'styled-is'

import { fontSmoothing } from '../../../styles'

import Icon from '../../Icon'

import { signOut } from '../../../redux/actions/authorization'
import {
  getCurrentUser,
  getCurrentRole,
  getUnreadNotificationCount,
} from '../../../redux/reducers'

const Nav = styled.nav`
  @media (min-width: 1440px) {
    grid-area: nav;
  }
`

const NavList = styled.ul`
  font-size: 30px;

  position: fixed;
  top: 38px;
  right: 0;
  width: 100%;
  height: calc(100vh - 38px);
  overflow-y: auto;
  //background: linear-gradient(45deg, #0F6E2D 0%, #8CAF2D 100%);
  //background: linear-gradient(45deg, #8CAF2D 100%, #0F6E2D 0%);
  background: linear-gradient(120deg, #0F6E2D 0%, #8CAF2D 100%);

  @media (min-width: 1440px) {
    position: static;
    transform: initial;
    height: 100%;
  }

  list-style-type: none;
  margin: 0;
  padding: 50px 32px;

  text-align: center;

  will-change: transform;
  transition: transform 0.5s ease;
  transform: translateX(-102%);

  z-index: 1;

  ${fontSmoothing};

  ${is('visible')`
    transform: translateX(0);
  `};

  & > li:not(:last-child) {
    margin-bottom: 32px;
  }

  & > li:first-child {
    display: flex;
    margin-bottom: 64px;

    & > a,
    & > span {
      margin-right: 32px;

      &:last-child {
        margin-left: auto;
        margin-right: 0;
      }
    }
  }
`

const CloseMenu = styled(Icon)`
  @media (min-width: 1440px) {
    display: none;
  }
`

const NavItem = styled(NavLink)`
  text-decoration: none;
  font-weight: normal;
  color: ${(props) => props.theme.colors.inactive};
`

const UnreadNotificationCount = styled.sup`
  font-size: 0.5rem;
  display: inline-flex;
  margin-left: 0.2rem;
  width: 1rem;
  height: 1rem;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.brandPrimary};
  color: ${(props) => props.theme.colors.background};
`

const mapStateToProps = (state) => ({
  currentUser: getCurrentUser(state),
  unreadNotificationCount: getUnreadNotificationCount(state),
})

const mapDispatchToProps = (dispatch) => ({
  signOut: () => dispatch(signOut()),
})

const MainNav = ({
  isVisible,
  setVisible,
  currentUser,
  signOut,
  t,
  theme,
  unreadNotificationCount,
}) => {
  const close = () => setVisible(false)

  const role = useSelector(getCurrentRole)
  const isFarmer = role === 'farmer'

  return currentUser ? (
    <Nav>
      <NavList visible={isVisible}>
        <li>
          <NavItem onClick={close} to="/profile">
            <Icon
              name="user"
              bordered={false}
              color={theme.colors.background}
            />
          </NavItem>
          <Icon
            name="logout"
            onClick={() => {
              close()
              signOut()
            }}
            bordered={false}
            color={theme.colors.background}
          />
          <Icon
            name="sync"
            onClick={() => {
              close()
              window.location.reload()
            }}
            bordered={false}
            color={theme.colors.background}
          />
          <CloseMenu
            name="close"
            onClick={close}
            bordered={false}
            color={theme.colors.background}
          />
        </li>
        <li>
          <NavItem onClick={close} to="/dashboard">
            {t('nav:dashboard')}
          </NavItem>
        </li>
        <li>
          <NavItem onClick={close} to="/map">
            {t('nav:map')}
          </NavItem>
        </li>
        {!isFarmer && (
          <li>
            <NavItem onClick={close} to="/programs">
              {t('nav:programs')}
            </NavItem>
          </li>
        )}
        <li>
          <NavItem onClick={close} to="/notifications">
            {t('nav:notifications')}
            {(() => {
              if (unreadNotificationCount > 0) {
                return (
                  <UnreadNotificationCount>
                    {unreadNotificationCount}
                  </UnreadNotificationCount>
                )
              }
            })()}
          </NavItem>
        </li>

        {!isFarmer && (
          <li>
            <NavItem onClick={close} to="/modules">
              {t('nav:modules')}
            </NavItem>
          </li>
        )}

        {!isFarmer && (
          <li>
            <NavItem onClick={close} to="/projects">
              {t('nav:projects')}
            </NavItem>
          </li>
        )}
      </NavList>
    </Nav>
  ) : null
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withTheme(MainNav)))
