import React from 'react'
import styled, { keyframes, withTheme } from 'styled-components'
import is, { isNot } from 'styled-is'

import Icon from '../Icon'

export const StyledButton = styled.button`
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  font-weight: 600;
  font-size: ${({ theme }) => theme.font.small};
  font-family: 'Asap', sans-serif;
  line-height: 24px;

  cursor: pointer;

  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => props.theme.colors.brandPrimary};
  //padding: 0 2rem;
  //margin: 1rem;

  height: 38px;
  border-radius: 19px;

  ${isNot('autoSize')`
    width: 132px;
  `};

  ${is('smaller')`
    width: 100px;
  `};

  &:focus {
    outline: none;
    border-width: 2px;
    //padding: 0 calc(2rem - 1px);
  }

  &:active {
    background: ${(props) => props.theme.colors.brandGradient};
    //color: 'black';
  }

  ${is('waiting')`
    background-color: ${(props) => props.theme.colors.gray};
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

export const Spinner = styled(Icon).attrs(({ theme }) => ({
  name: 'spinner',
  bordered: false,
  color: theme.colors.background,
}))`
  animation: ${rotate} 2s linear infinite;
`

export const Button = withTheme(
  ({ children, waiting, disabled, theme, ...props }) => {
    return (
      <StyledButton
        waiting={waiting}
        disabled={waiting ? true : disabled}
        {...props}
      >
        {waiting ? <Spinner /> : children}
      </StyledButton>
    )
  }
)
