import { combineReducers } from 'redux'

import { FETCH_RESOURCE_SUCCESS } from '../actions/resource'
import { SIGN_OUT_SUCCESS } from '../actions/authorization'

export const META_KEY = '__meta__'

const singular = (state = {}, operation, payload) => {
  switch (operation) {
    case 'create': {
      return Object.assign({}, payload)
    }
    case 'read':
    case 'update': {
      if (Array.isArray(payload)) {
        return Object.assign([], payload)
      } else {
        return Object.assign({}, state, payload)
      }
    }
    default: {
      return state
    }
  }
}

const createById = (name) => (state = {}, { type, resource, payload }) => {
  // return empty state if user signed out
  if (type == SIGN_OUT_SUCCESS) {
    return {}
  }

  if (
    type !== FETCH_RESOURCE_SUCCESS ||
    resource == null ||
    resource.name !== name
  ) {
    return state
  }

  const { operation } = resource
  const { json, meta } = payload || {}

  switch (operation) {
    case 'list':
    case 'swapSortOrder': {
      return json.reduce((newState, response) => {
        return {
          [META_KEY]: meta,
          ...newState,
          [response.id]: singular(state[response.id], 'read', response),
        }
      }, {})
    }
    case 'create': {
      return {
        ...state,
        [json.id]: singular(undefined, operation, json),
      }
    }
    case 'read':
    case 'update': {
      if (json != null) {
        return {
          ...state,
          [resource.id || json.id]: singular(
            state[resource.id || json.id],
            operation,
            json
          ),
        }
      } else {
        return state
      }
    }
    case 'destroy': {
      const { [resource.id]: _, ...rest } = state
      return rest
    }
    default:
      return state
  }
}

export const createResource = (name) => {
  const reducer = createById(name)

  const selectors = {
    getAll: (state, options = {}) => {
      const { filter, sort } = options
      const { [META_KEY]: _, ...collection } = state[name]
      let ret = Object.values(collection)
      if (filter != null) {
        ret = ret.filter(filter)
      }
      if (sort != null) {
        ret = ret.sort(sort)
      }
      return ret
    },
    getById: (state, id) => state[name][id],
    getCollectionMeta: (state, key) => state[name][META_KEY]?.[key],
  }

  return {
    reducer,
    selectors,
  }
}

const projectResource = createResource('projects')
const programResource = createResource('programs')
const valveGroupResource = createResource('valve_groups')
const valveResource = createResource('valves')
const temporarySuspensionsResource = createResource('temporary_suspensions')
const moduleResource = createResource('modules')
const shiftResource = createResource('shifts')
const publishAttemptsResource = createResource('publish')
const notificationsResource = createResource('notifications')
const dashbaordResource = createResource('dashboard')
const profileResource = createResource('profile')
const sensorValuesResource = createResource('sensor_values')
const searchResource = createResource('search')

export default combineReducers({
  projects: projectResource.reducer,
  programs: programResource.reducer,
  valve_groups: valveGroupResource.reducer,
  valves: valveResource.reducer,
  modules: moduleResource.reducer,
  shifts: shiftResource.reducer,
  publish: publishAttemptsResource.reducer,
  notifications: notificationsResource.reducer,
  dashboard: dashbaordResource.reducer,
  profile: profileResource.reducer,
  sensor_values: sensorValuesResource.reducer,
  search: searchResource.reducer,
  temporary_suspensions: temporarySuspensionsResource.reducer,
})

export const projects = projectResource.selectors
export const programs = programResource.selectors
export const valveGroups = valveGroupResource.selectors
export const valves = valveResource.selectors
export const modules = moduleResource.selectors
export const shifts = shiftResource.selectors
export const publishAttempts = publishAttemptsResource.selectors
export const notifications = notificationsResource.selectors
export const dashboard = dashbaordResource.selectors
export const profile = profileResource.selectors
export const sensorValues = sensorValuesResource.selectors
export const search = searchResource.selectors
export const temporarySuspensions = temporarySuspensionsResource.selectors
